/* You can add global styles to this file, and also import other style files */
@import 'https://use.fontawesome.com/releases/v5.5.0/css/all.css';
@import 'colors';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
